<template>
    <div class="officeflex-row flex-j-center flex-a-start">
        
        <div class="flex-row flex-j-start flex-a-start">
<!--            <Category @select="onCategorySelect"/>-->
            <!--            <banner/>-->
            <!--            <information/>-->
            <div style="width: 0.2rem"></div>
            <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
                
                
                <div class="list" style="width: 20rem;padding: 0 0.1rem">
<!--                    <condition-search :list="searchList" @active="onConditionActive"/>-->
                    <select-search :list="searchList" @active="onConditionActive"></select-search>
                    <office-list-cell v-for="(item, index) in list" :key="index" :item="item"></office-list-cell>
                </div>
                <empty-page v-if="!list||list.length===0" msg="暂无相关职位"/>
            </refresh-list>
        </div>
    
    </div>
</template>

<script>
// office
import RefreshList from "@/components/refresh-list";
import OfficeListCell from "@/components/office-list-cell";
// import Category from "@/components/home/category";
import EmptyPage from "@/components/empty-page";
// import ConditionSearch from "@/components/condition-search";
import SelectSearch from "@/components/select-search";

export default {
    name: "office",
    components: {
        SelectSearch,
        // ConditionSearch,
        EmptyPage,
        // Category,
        OfficeListCell, RefreshList},
    data() {
        return {
            searchList: [
                {
                    title: '职位', field: 'postName', list: []
                },
                {
                    title: '所属镇街', field: 'areaName', list: []
                },
                {
                    title: '薪资范围', field: 'salary', list: [
                        {
                            name: '不限',
                            value: -1
                        },
                        {
                            name: '2000~5000',
                            value: 0
                        },
                        {
                            name: '5000~10000',
                            value: 1
                        },
                        {
                            name: '10000~20000',
                            value: 2
                        },
                        {
                            name: '>20000',
                            value: 3
                        }, {
                            name: '面议',
                            value: 4
                        },
                    ]
                }
            ],
            list: [],
            pageSize: 15,
            pageIndex: 1,
            searchParams: {
                postName: "",
                areaName: "",
                salary: "",
            }
        }
    },
    activated() {
        this.searchParams.postName = this.$route.query.postName || "";
        this.loadPageList();
        
    },
    mounted() {
        this.searchParams.postName = this.$route.query.postName || "";
        this.loadPageList();
        // 监听头部搜索框搜索事件
        window.$onIndexHeaderSearch = (keyword) => {
            this.searchParams.postName = keyword;
            // this.searchParams.keyword = keyword;
            // console.log(keyword)
            this.pageIndex = 1;
            this.list = [];
            this.loadPageList();
        }
        
        let postNameList = $app.getStorageSync('businessList') || [];
        postNameList = JSON.stringify(postNameList).replace(/professionList/g, "children");
        const postList = JSON.parse(postNameList);
        this.searchList[0].list = postList;
        
        let areaNameList = $app.getStorageSync('areaList') || [];
        areaNameList = areaNameList.map(item => {
            item.name = item.AreaName;
            item.value = item.AreaName;
            return item;
        });
        this.searchList[1].list = areaNameList;
    },
    methods: {
        onCategorySelect(item) {
            this.searchParams.postName = item.name;
            this.pageIndex = 1;
            this.loadPageList();
        },
        onConditionActive(item) {
            // console.log(item)
            this.searchParams[item.field] = item.name.replace(">", "").replace("面议", "");
            this.pageIndex = 1;
            this.loadPageList();
        },
        async loadPageList() {
            let salary = this.searchParams.salary || '~';
            
            const params = {
                areaName: this.searchParams.areaName || '',
                postName: this.searchParams.postName || "",
                salaryMin: salary.split("~")[0] || 0,
                salaryMax: salary.split("~")[1] || 0,
                postType: 1,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            };
            console.log(params);
            const res = await $app.httpGet({
                path: `/BasePostInfo/GetPostList`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];
                
                newList = newList.map(item => {
                    let Requirements = item.Requirements || "";
                    let Welfare = item.Welfare || "";
                    // 汉字逗号改为英文逗号
                    Requirements = Requirements.replace(/，/g, ',').replace(/、/g, ",");
                    item.RequirementsList = Requirements.split(",");
                    
                    Welfare = Welfare.replace(/，/g, ",").replace(/、/g, ",");
                    item.WelfareList = Welfare.split(",");
                    
                    if (item.RequirementsList[0] == "") {
                        item.RequirementsList = [];
                    }
                    if (item.WelfareList[0] == "") {
                        item.WelfareList = [];
                    }
                    
                    item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                    return item;
                });
                
                if (this.pageIndex == 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }
        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.office {
    width: 28rem;
    //background: #fff;
}
</style>
